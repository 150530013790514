<style lang="less" scoped>
	@import "../../variables.less";
	.file li {
		cursor: pointer;
        color: @baseColor;
		/* &:hover {
			background-color: lighten(@infoBlockBackground, 30%);
		} */
	}
</style>
<style>
.img_modal_wrap{
    display: flex;
    justify-content: center;
    max-height: 650px;
}
</style>
<template>
	<span v-if="files.length===0">没有文件</span>
    <ul class=file v-else>
        <li :key="i.fileurl" @click="open(i)" v-for='i in files'>
            <Icon :type="i.filetype|judgeType" />
            {{i.shortname}}
        </li>
    </ul>
	<!-- <Poptip title="相关文件" transfer v-else>
		<span style="color:dodgerblue;cursor: pointer">点击查看</span>
		<div slot=content>
			<ul class=file>
				<li :key="i.fileurl" @click="open(i)" v-for='i in files'>
					<Icon :type="i.filetype|judgeType" />
					{{i.shortname}}
				</li>
			</ul>
		</div>
	</Poptip> -->
</template>
<script lang=ts>
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { Icon, Modal, Poptip } from "view-design";
	import { CreateElement, VNode } from "vue";

	interface file {
		filetype:string,
		fileurl:string,
		name:string,
		shortname:string
	}

	@Component({
		components: {
			Poptip,
			Icon
		},
		filters: {
			judgeType (type:string):string {
				let n = "document";
				(/image/.test(type) && (n = "ios-images-outline")) ||
				(/video/.test(type) && (n = "ios-videocam-outline")) ||
				(/audio/.test(type) && (n = "ios-musical-notes-outline")) ||
				(/application/.test(type) && (n = "ios-document-outline"));
				return n;
			}
		}
	})
		export default class OperationFileList extends Vue {
		@Prop(Array) readonly files?:file[];

		open (file:file) {
			const mimeType:string = file.filetype;
			if (/audio/.test(mimeType)) {
				const audio = new Audio(file.fileurl);
				audio.addEventListener("canplaythrough", () => {
					audio.play();
				});
			} else if (/application/.test(mimeType)) {
				// window.open("https://view.officeapps.live.com/op/view.aspx?src=" + location.origin + file.fileurl);
				window.open(location.origin + file.fileurl);
			} else if (/(image)|(video)/.test(mimeType)) {
				const config = {
					// title: "查看文件",
					render (h:CreateElement):VNode {
						if (/image/.test(mimeType)) {
							return h("div", {
                                class: "img_modal_wrap"
                            }, [h("img", { attrs: { src: file.fileurl }, staticStyle: { "max-height": "487px", "max-width": "100%" } })]);
						} else if (/video/.test(mimeType)) {
							return h("div", {
                                class: "img_modal_wrap"
                            }, [
                                h("video", {
                                    attrs: { src: file.fileurl, autoplay: true, controls: true },
                                    staticStyle: { width: "100%" }
                                })
                            ]);
						} else {
							return h();
						}
					},
					width: 800,
					okText: "关闭"
				};
				// @ts-ignore
				Modal.info(config);
			} else {
				window.open(file.fileurl);
			}
		}
	}

</script>
