
@import "../../../variables.less";
@import "../../../resource/common.less";
@currentWidth: 550px;
@attachWidth: unset;

.info {
	line-height: 2;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: @colorGary;
	margin-bottom: @middleSpace;
	.detail {
		width: 50%;
		padding-left: 20px;
        height: 40px;
        line-height: 40px;
		&:nth-child(odd):before {
			content: "";
			display: block;
			width: 200%;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
		}

		label {
			display: inline-block;
			width: 80px;
			/* text-align: justify;
			text-align-last: justify; */
			float: left;
            color: #999;
			+* {
				display: block;
				margin-left: 100px;
				text-overflow: ellipsis;
				white-space: nowrap;
                color: #333;
				overflow: hidden;
			}
		}
	}
}
.content{
    border-radius: 4px;
    background:@contentBg;
    box-shadow: 0 0 10px 1px @boxshadow;
    margin: 10px;
    width: calc(100% - 20px);
}
.content2{
    margin: 0 10px 0;
    padding: 0 0 1px;
}
.content3{
    height: calc(100% - 20px);
}
.operation_height1{
    height: calc(100vh - 716px);
}
.operation_height2{
    height: calc(100vh - 466px);
}
.deal_wrap{
    max-height: 200px;
    overflow: auto;
}
.operation_content{
    .mian_title{
        font-size: 18px;
        width:100%;
        padding: 10px 10px;
        display:flex;
        font-weight: 600;
        position:relative;
        margin: 0 0 0 10px;
        line-height: 18px;
        &:before{
            content:"";
            position:absolute;
            width: 2px;
            height: 18px;
            top:11px;
            background: @baseColor;
            left: 1px;
        }
    }
    .gary_line{
        width: calc(100% - 20px);
        height: 2px;
        position:relative;
        left: 10px;
        bottom:0;
        background-color: rgb(247 247 250);
        &::after{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0px;
            background: #e0e2e3;
            right: 0;
        }
        &::before{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0;
            background: #e0e2e3;
            left: 0;
        }
    }
}
