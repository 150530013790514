
	@import "../../variables.less";
	@import "../../resource/common.less";
    .repair_content{
        border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }
