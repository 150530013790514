.steps .step {
  padding: 12px;
}
.steps .step:not(:last-child):before {
  content: "";
  display: block;
  width: 1px;
  border-left: 1px solid #4261ed57;
  position: absolute;
  left: 25px;
  top: 35px;
  bottom: -12px;
}
.steps .step .stepNumber {
  display: inline-block;
  font-size: 14px;
  line-height: 2;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #4261ED;
  color: #fff;
  text-align: center;
  margin-right: 6px;
}
.steps .step .stepText {
  font-size: 16px;
}
.steps .step .detail {
  margin-left: 30px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 4px;
  margin-top: 5px;
}
.steps .step .detail ul {
  line-height: 2;
}
.steps .step .detail ul label {
  display: inline-block;
  width: 80px;
  /* text-align: justify;
						text-align-last: justify; */
  float: left;
  color: #999;
}
.steps .step .detail ul li > span {
  margin-left: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
  display: block;
}
.steps .step .detail ul > li:first-child:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #ffffff;
}
