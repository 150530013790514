
	@import "../../variables.less";
	.steps {
		.step {
			padding: @bigSpace;
			&:not(:last-child):before {
				content: "";
				display: block;
				width: 1px;
				border-left: 1px solid #4261ed57;
				position: absolute;
				left: 25px;
				top: 35px;
				bottom: -1*@bigSpace;
			}

			.stepNumber {
				display: inline-block;
				font-size: @fontSizeC;
				line-height: 2;
				width: 2 * @fontSizeC;
				height: 2 * @fontSizeC;
				border-radius: 50%;
				background-color: @baseColor;
                color: #fff;
				text-align: center;
				margin-right: @middleSpace;
			}
            .stepText{
                font-size: 16px;
            }
			.detail {
				margin-left: 30px;
				border: 1px solid lighten(@infoBlockBackground, 30%);
				border-radius: 5px;
				padding: @smallSpace;
                margin-top: 5px;
				ul {
					line-height: 2;

					label {
						display: inline-block;
						width: 80px;
						/* text-align: justify;
						text-align-last: justify; */
						float: left;
                        color:#999;
					}

					li > span {
						margin-left: 100px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
                        color:#333;
						display: block;
					}

					> li:first-child:after {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						position: absolute;
						bottom: 0;
						border-bottom: 1px solid lighten(@infoBlockBackground, 30%);
					}
				}
			}
		}
	}
